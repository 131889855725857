@import '~leaflet/dist/leaflet.css';

::-webkit-scrollbar {
    width: 0;
}

.main-container {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    background-color: rgba(0,0,0,.05);
}

/* Sticky header - NavBar, Map, TankFilter */
.sticky {
    position: sticky;
    top: 0;
}

.map {
    position: sticky;
    top: 5vh;
    width: 100%;
    z-index: 1;
    margin-bottom: -2vh;
}

.leaflet-container {
    width: 100%;
    height: 35vh;
  }

/* NavBar styles */
.ui.stackable.nav-bar-menu.menu {
    margin: 0;
    height: 5vh;
}
.app-name {
    font-size: 2vh;
	font-weight: bold;
    color: rgb(0, 102, 255);
}

/* TankFilter styles */
.tank-filter {
    position: sticky;
}
.ui.tank-filter-menu.menu {
    height: 5vh;
}

.ui.segment.no-content {
    padding: 0;
    margin: 0;
}

/* Tank styles */
.item.tank-menu-item {
    height: auto;
    border: 0.5px solid lightgrey;
}
.item.tank-menu-item::after {   /* стиль стрелочки на активном item-е */
    /* padding: 7px; */
}
.tank-block {
    margin: 0;
}
.ui.grid.tank-grid {
    padding-top: 10px;
    padding-bottom: 10px;
}
.ui.segment.name {
    padding: 0;
    background-color: rgba(223, 215, 215, 0.05);
    border: 0px;
    box-shadow: none;
}
.ui.segment.ping {
    padding: 0;
    background-color: rgba(223, 215, 215, 0.05);
    border: 0px;
    box-shadow: none;
}
.tank-name {
    margin-top: 0.4vh;
    font-size: 18px;
    font-weight: bold;
}
.indication-name {
    font-size: 14px;
    font-weight: bold;
    color: #3B83C0
}
.indication-value {
    font-size: 14px;
    font-weight: bold;
    color: #3B83C0
}
.ui.segment.devices {
    width: 100%;
    margin-top: 0;
    margin-right: 10px;
    margin-left: 20px;
    margin-bottom: 0;
    padding: 0;
    background-color: rgba(223, 215, 215, 0.05);
    border: 0px;
    box-shadow: none;
}

/* TankContent styles */
.ui.bottom.attached.segment.active.tab.tank-tab-pane {
    padding: 0;
    border: 0px;
    background-color: rgba(223, 215, 215, 0.05);
}
.ui.segment.segment-tank-content {
    position: sticky;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}
.tank-content {
    height: auto;
    background-color: rgba(223, 215, 215, 0.05);
}
.tank-state {
}
.all-data {
    padding-top: 10px;
}
/* Основные вкладки контента */
.ui.bottom.attached.segment.active.tab.trips-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.movings-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.egais-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.files-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.info-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.func-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.nav {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.sync {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.daily {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.fivemins {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.users-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.products-tab {
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.contractors-tab {
    padding: 10px;
}
.ui.segment.data-block {
    padding: 0;
    overflow: hidden;
}

.soon { /* промежуточный сегмент; изначально был для компонента Apologies */
    height: 100%;
    overflow: auto;
}

.ui.segment.files {
    height: 100%;
}
.ui.segment.files-table {
    margin: 0;
    padding: 10px 0px;
    overflow: auto;
    height: calc(100% - 50px);
}
.ui.segment.trips {
    height: 100%;
}
.ui.segment.trips-table {
    margin: 0;
    padding: 10px 0px;
    overflow: auto;
    height: calc(100% - 50px);
}
.ui.segment.egais {
    height: 100%;
}
.ui.segment.egais-table {
    margin: 0;
    padding: 10px 0px;
    overflow: auto;
    height: calc(100% - 90px);
}
.ui.select-line.menu {
    margin: 0;
    padding: 0;
}

/* AddingProduct styles */
.ui.fullscreen.modal.transition.visible.active.modal-add-product {
    position: relative;
    height: 100%;
}
.add-product {
    height: 100%;
}
.ui.divided.padded.two.column.grid.add-grid {
    height: 90%;
}
.column.column-tmpproduct {
    height: 100%;
}
.column.column-tablearea3 {
    height: 100%;
}
.ui.segment.tmpproduct {
    height: 100%;
    padding: 0;
    overflow: hidden;
}
.ui.segment.tmpproduct-body {
    height: calc(100% - 50px);
}
.ui.segment.tmpproduct-rows {
    overflow: auto;
    height: calc(100% - 50px);
}
.ui.segment.tablearea3 {
    height: 100%;
    padding: 0;
    overflow: hidden;
}
.tablearea3-body-div {
    height: calc(100% - 50px);
}
.ui.segment.tablearea3-body {
    height: 100%;
}
.ui.segment.tablearea3-rows {
    overflow: auto;
    height: calc(100% - 50px);
}
.ui.padded.two.column.grid.add-footer {
    height: 10%;
}
.ui.segment.instructions {
}
.instructions-text {
    margin: 0 10px;
    font-size: 14px;
	font-weight: bold;
}
.ui.grid.footer-filter {
    margin: 0;
}

/* Denstables styles */
.filepond--root.filepond--hopper {
    margin: 0;
}
.filepond--drop-label { /* the text color of the drop label*/
    color: #555;
}
.filepond--item-panel { /* the border radius of the file item */
    border-radius: 0.5em;
}
.filepond--item-panel { /* the background color of the file and file panel (used when dropping an image) */
    height: 2em;
    background-color: #555;
}
.filepond--file-action-button { /* the background color of the black action buttons */
    background-color: rgba(0, 0, 0, 0.5);
}
.filepond--file-action-button { /* the icon color of the black action buttons */
    color: white;
}
.filepond--file-action-button:hover,
.filepond--file-action-button:focus {   /* the color of the focus ring */
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.9);
}
.filepond--file {   /* the text color of the file status and info labels */
    font-size: 1.5em;
    color: white;
}
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {   /* error state color */
    background-color: red;
}
[data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: green;
}
.filepond--panel-root { /* bordered drop area */
    height: 2em;
}

/* ADMINPanel */
.ui.fullscreen.modal.transition.visible.active.modal-admin-panel {
    position: relative;
}
.admin-panel {
    height: 100%;
}
.admin-all-data {
    padding-top: 10px;
    height: 100%;
}
.admin-tab{
    height: 100%;
}
.ui.bottom.attached.segment.active.tab.users-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.carriers-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.tanks-info {
    padding: 10px;
    height: 95%;
}
.ui.segment.data-block-admin {
    padding: 0;
    height: 100%;
    overflow: hidden;
}
.ui.segment.users {
    height: 100%;
}
.ui.segment.users-table {
    overflow: auto;
}

/* CarrierPanel */
.blue.ellipsis.vertical.large.icon {
    margin: 0;
}
.ui.fullscreen.modal.transition.visible.active.modal-carrier-panel {
    position: relative;
}
.carrier-panel {
    height: 100%;
}
.carrier-all-data {
    padding-top: 10px;
    height: 100%;
}
.carrier-tab{
    height: 100%;
}
.ui.attached.tabular.menu {
    height: 5%;
}
.ui.bottom.attached.segment.active.tab.utm-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.carrier-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.shipper-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.consignee-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.producer-info {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.sync-history {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.carrier-notices {
    padding: 10px;
    height: 95%;
}
.carrier-notices-tab {
    height: 100%;
}
.ui.bottom.attached.segment.active.tab.send-notice {
    padding: 10px;
    height: 95%;
}
.ui.bottom.attached.segment.active.tab.notice-history {
    padding: 10px;
    height: 93%;
}
.ui.segment.data-block-carrier {
    padding: 0;
    height: 100%;
    overflow: hidden;
}
.ui.segment.data-block-notice-history {
    padding: 0;
    height: 100%;
    overflow: hidden;
}
.div-notice-history {
    height: 100%;
}
.ui.basic.segment.notice-history {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
.notice-history-filters {
    height: 60px;
}
.ui.segment.notice-history-table {
    margin: 0;
    padding: 10px 0px 0px 0px;
    overflow: auto;
    height: calc(100% - 60px);
}
.ui.left.icon.input {
    width: 150px;
}
#utm-conn {
    margin: 0;
}
#carrier-sync {
    margin: 0;
}
.orgs-info {
    margin: 0;
    overflow: auto;
    height: 100%;
}
.sync-history {
    margin: 0;
    overflow: auto;
    height: 100%;
}
.notice-form {
    margin: 0;
    overflow: auto;
    height: 100%;
}
.notice-history {
    margin: 0;
    overflow: auto;
    height: 100%;
}

/* FileContainer styles */
.ui.large.modal.transition.visible.active {
    position: relative;
    height: 85%;
}
.ui.header {
    height: 7%;
}
.send-file-content {
    height: 84%;
}
.send-file-content-div {
    padding-top: 10px;
    height: 100%;
}
.send-file-content-tab {
    height: 100%;
}
.file-content {
    height: 91%;
}
.file-content-div {
    padding-top: 10px;
    height: 100%;
}
.file-content-tab {
    height: 100%;
}
.ui.attached.tabular.menu {
    height: 5%;
}
.ui.bottom.attached.segment.active.tab.xml-view {
    height: 95%;
    padding: 10px;
}
.ui.bottom.attached.segment.active.tab.form-view {
    height: 95%;
    padding: 10px;
}
.actions {
    height: 9%;
}

#file-viewer {
    height: 100%;
}
.field {
    height: 100%;
}
#file-viewer-area {
    background: rgba(235, 232, 232, 0.39);
    color: darkgreen;
    min-height: 100%;
    max-height: 100%;
}
#notice-tmp-form {
    background: rgba(235, 232, 232, 0.39);
}
.ticket-label {
    vertical-align: -5px;
}
#comment-viewer {
    width: 60%;
    height: 120px;
    float: right;
}
#comment-viewer-area {
    min-height: 100%;
    max-height: 100%;
}

/* Редактирование данных УТМ */
#utm-field {
    margin-top: -6px;
    padding: 7px;
}

/* Шрифт при заполнении уведомления */
#notice-form-input {
    font-size: 15px;
}